.error {
  transition: all .2s;
  color: #FF0000; }

.pass_checked_error, .error_block_false {
  transition: all .2s;
  color: #FF0000;
  text-align: center;
  margin-bottom: 10px; }

.load_spin {
  display: none; }

.load_spin_active {
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(77, 83, 89, 0.7);
  display: block !important; }
  .load_spin_active img {
    position: relative;
    width: 50px;
    display: block;
    height: auto;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%; }

.true_form {
  position: absolute;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  padding: 0;
  width: 500px;
  height: 250px;
  background: #fff !important;
  min-width: unset;
  text-align: center;
  min-height: unset; }
  .true_form .fancybox-button svg {
    display: none; }

.form_true_img {
  margin-top: 35px;
  width: 100px; }

.usually_modal_text {
  font-size: 16px;
  margin-top: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal; }

@media (max-width: 600px) {
  .true_form {
    width: 85%; }
  .usually_modal_text {
    font-size: 15px; } }
