@charset "UTF-8";
.header {
  position: fixed;
  background: #fff;
  z-index: 999;
  width: 100%;
  border-bottom: 1px solid #CCCCCC; }
  .header_info__block {
    position: relative;
    width: 100%;
    height: 44px;
    background: #2E2E2E; }
    .header_info__block .container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .header_info_time {
    margin-left: 5px; }
    .header_info_time__block {
      display: flex;
      cursor: default;
      align-items: center; }
  .header_info_address {
    transition: all .2s; }
    .header_info_address:hover {
      color: #F28820; }
    .header_info_address__block {
      position: absolute;
      top: 13px;
      left: 50%;
      transform: translateX(-50%); }
  .header_info_contacts__block {
    display: flex;
    align-items: center; }
  .header_info_contacts_phone {
    transition: all .2s;
    margin-right: 12px; }
    .header_info_contacts_phone:hover {
      color: #F28820; }
  .header_info_contacts_link {
    display: flex;
    margin: 0px 6px; }
    .header_info_contacts_link svg path {
      transition: all .2s; }
    .header_info_contacts_link:hover path {
      fill: #F28820; }
  .header_content_item_error {
    padding: 5px 10px; }
  .header_content__block {
    position: relative;
    width: 100%;
    height: 90px;
    background: #fff; }
    .header_content__block .container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .header_content_label {
    background: #F2F2F2;
    border: 1px solid #ACACAC;
    border-radius: 31px;
    padding: 7px 15px;
    width: 350px;
    display: flex;
    align-items: center;
    cursor: pointer; }
    .header_content_label img {
      margin-right: 12px; }
    .header_content_label input:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px #F2F2F2 !important;
      /* Цвет фона */
      -webkit-text-fill-color: #000 !important;
      /* цвет текста */
      color: #000 !important;
      /* цвет текста */ }
  .header_content_svg {
    cursor: pointer; }
    .header_content_svg path {
      transition: all .2s; }
    .header_content_svg:hover path {
      fill: #F28820; }
  .header_content_user:hover path {
    stroke: #F28820; }
  .header_content_user_img {
    display: flex;
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%; }
    .header_content_user_img img {
      transition: all .3s;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(100%); }
    .header_content_user_img:hover img {
      filter: grayscale(0%); }
  .header_content_shop {
    position: relative;
    margin-left: 27px;
    margin-right: 37px; }
    .header_content_shop_counter {
      display: block;
      transition: all .2s;
      position: absolute;
      width: 18px;
      height: 18px;
      top: -10px;
      right: -10px;
      background: #E52D23;
      font-style: normal;
      font-weight: bold;
      color: #fff;
      border-radius: 50%;
      transform: scale(1);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 10px;
      line-height: 1px; }
  .header_content_right {
    display: flex;
    align-items: center; }
  .header_content_left {
    display: flex;
    align-items: center; }
  .header_content_nav {
    transition: all .2s;
    margin: 0px 16px; }
    .header_content_nav__block {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
    .header_content_nav:hover {
      color: #F28820; }
  .header_content_logo {
    display: block;
    margin-right: 20px;
    width: 160px; }
    .header_content_logo img {
      width: 100%; }

.main_search {
  outline: none;
  background: transparent;
  cursor: pointer;
  border: none;
  width: calc(100% - 20px); }

.lang_arrow {
  margin-left: 5px;
  width: 11px;
  height: 7px;
  position: relative; }
  .lang_arrow img {
    transition: all .2s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(0deg); }

.lang_active {
  display: flex;
  align-items: center;
  cursor: pointer; }

.lang_select {
  visibility: hidden;
  display: block;
  transition: all .2s;
  position: absolute;
  top: 100px;
  opacity: 0;
  right: 0px;
  width: 200px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
  border-radius: 2px; }

.lang_profile {
  transition: all .2s;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background: #fff;
  cursor: pointer; }
  .lang_profile_img {
    display: block;
    width: 28px;
    height: 17px; }
    .lang_profile_img img {
      width: 100%;
      height: 100%; }
  .lang_profile_text {
    display: block;
    margin-left: 12px; }
  .lang_profile:hover {
    background: rgba(242, 137, 32, 0.105); }

.lang__block {
  position: relative;
  top: -4px;
  margin-left: 20px; }
  .lang__block.active .lang_select {
    top: 40px;
    opacity: 1;
    visibility: visible; }
  .lang__block.active .lang_arrow img {
    transform: rotate(180deg); }

.anim_left {
  animation-duration: 1s;
  animation-name: dinamic_left;
  animation-fill-mode: forwards; }

@keyframes dinamic_left {
  0% {
    left: -100px;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    left: 0;
    opacity: 1; } }

.anim_right {
  animation-duration: 1s;
  animation-name: dinamic_right;
  animation-fill-mode: forwards; }

@keyframes dinamic_right {
  0% {
    right: -100px;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    right: 0;
    opacity: 1; } }

.anim_top {
  animation-duration: 1s;
  animation-name: dinamic_top;
  animation-fill-mode: forwards; }

@keyframes dinamic_top {
  0% {
    top: -20px;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    top: 0;
    opacity: 1; } }

.anim_bottom {
  animation-duration: 1s;
  animation-name: dinamic_bottom;
  animation-fill-mode: forwards; }

@keyframes dinamic_bottom {
  0% {
    bottom: -20px;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    bottom: 0;
    opacity: 1; } }

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box; }

.menu-trigger {
  position: relative;
  width: 24px;
  height: 24px;
  top: 2px; }

.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
  border-radius: 4px; }

.menu-trigger span:nth-of-type(1) {
  top: 0; }

.menu-trigger span:nth-of-type(2) {
  top: 7px; }

.menu-trigger span:nth-of-type(3) {
  top: 14px; }

.menu-trigger.type7 span:nth-of-type(1) {
  -webkit-animation: menu-bar07-01 .75s forwards;
  animation: menu-bar07-01 .75s forwards; }

@-webkit-keyframes menu-bar07-01 {
  0% {
    -webkit-transform: translateY(20px) rotate(45deg); }
  50% {
    -webkit-transform: translateY(20px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0); } }

@keyframes menu-bar07-01 {
  0% {
    transform: translateY(20px) rotate(45deg); }
  50% {
    transform: translateY(20px) rotate(0); }
  100% {
    transform: translateY(0) rotate(0); } }

.menu-trigger.type7 span:nth-of-type(2) {
  transition: all .25s .25s;
  opacity: 1; }

.menu-trigger.type7 span:nth-of-type(3) {
  -webkit-animation: menu-bar07-02 .75s forwards;
  animation: menu-bar07-02 .75s forwards; }

@-webkit-keyframes menu-bar07-02 {
  0% {
    -webkit-transform: translateY(-15px) rotate(-45deg); }
  50% {
    -webkit-transform: translateY(-15px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0); } }

@keyframes menu-bar07-02 {
  0% {
    transform: translateY(-15px) rotate(-45deg); }
  50% {
    transform: translateY(-15px) rotate(0); }
  100% {
    transform: translateY(0) rotate(0); } }

.menu-trigger.active span:nth-of-type(1) {
  -webkit-animation: active-menu-bar07-01 .75s forwards;
  animation: active-menu-bar07-01 .75s forwards; }

@-webkit-keyframes active-menu-bar07-01 {
  0% {
    -webkit-transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(15px) rotate(0); }
  100% {
    -webkit-transform: translateY(9px) rotate(45deg); } }

@keyframes active-menu-bar07-01 {
  0% {
    transform: translateY(0) rotate(0); }
  50% {
    transform: translateY(15px) rotate(0); }
  100% {
    transform: translateY(9px) rotate(45deg); } }

.menu-trigger.active span:nth-of-type(2) {
  opacity: 0; }

.menu-trigger.active span:nth-of-type(3) {
  -webkit-animation: active-menu-bar07-02 .75s forwards;
  animation: active-menu-bar07-02 .75s forwards; }

@-webkit-keyframes active-menu-bar07-02 {
  0% {
    -webkit-transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(-15px) rotate(0); }
  100% {
    -webkit-transform: translateY(-5px) rotate(-45deg); } }

@keyframes active-menu-bar07-02 {
  0% {
    transform: translateY(0) rotate(0); }
  50% {
    transform: translateY(-15px) rotate(0); }
  100% {
    transform: translateY(-5px) rotate(-45deg); } }

.header_menu {
  display: none; }

.mobile_item {
  display: none; }

.header_mobile_search {
  display: none; }

.header_content_search.show .header_content_item__block {
  top: 55px;
  visibility: visible;
  opacity: 1; }

.header_content_item {
  display: block;
  width: calc(100% - 70px); }
  .header_content_item__block {
    transition: all .2s;
    display: block;
    position: absolute;
    padding: 10px 0;
    top: 155px;
    opacity: 0;
    visibility: hidden;
    width: 450px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    max-height: 400px;
    overflow-y: auto; }
  .header_content_item_title {
    transition: all .2s;
    display: block; }
  .header_content_item_type {
    display: block;
    margin: 5px 0; }
  .header_content_item_price {
    display: block; }
  .header_content_item_img {
    display: block;
    width: 60px;
    height: 60px;
    margin-right: 10px; }
    .header_content_item_img img {
      width: 100%;
      height: 100%; }
  .header_content_item_profile {
    transition: all .2s;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 30px; }
    .header_content_item_profile:hover .header_content_item_title {
      color: #F28820; }

@media (max-width: 1400px) {
  .header_content_label {
    width: 250px;
    padding: 7px 10px; }
    .header_content_label img {
      margin-right: 7px; } }

@media (max-width: 1230px) {
  .header_info_address__block {
    display: none; } }

@media (max-width: 992px) {
  .header_content__block {
    height: 70px; }
  .header_content_nav {
    font-size: 24px; }
    .header_content_nav_1 {
      margin-top: 50px !important; }
  .header {
    overflow: unset; }
    .header_menu {
      transition: all .2s;
      display: block;
      position: relative;
      z-index: 1000;
      left: 0;
      margin-right: 10px; }
    .header .mobile_item {
      display: block;
      border-bottom: 1px solid #E4E4E4; }
    .header .header_content_nav {
      margin: 12px 0; }
    .header .header_content_nav__block {
      transition: all .2s;
      position: absolute;
      flex-direction: column;
      display: flex;
      top: 0;
      left: -100%;
      opacity: 0;
      background: #fff;
      width: 330px;
      height: 100vh;
      z-index: 999;
      padding: 24px 15px;
      align-items: flex-start; }
      .header .header_content_nav__block:after {
        transition: all .2s;
        transition-delay: all .2s;
        content: '';
        opacity: 0;
        position: absolute;
        pointer-events: none;
        z-index: -999;
        left: calc(100vw + 330px);
        top: 0;
        width: calc(100vw - 330px);
        height: 100vh;
        background: rgba(0, 0, 0, 0.618); }
    .header.active .header_content_nav__block {
      left: 0;
      opacity: 1; }
      .header.active .header_content_nav__block:after {
        left: 330px;
        opacity: 1;
        z-index: 1; }
    .header.active .header_menu {
      left: 249px;
      top: 0; } }

@media (max-width: 725px) {
  .header_mobile_search {
    display: block; }
  .header_content_item__block {
    width: 100%; }
  .header_content_label {
    transition: all .2s;
    width: 0px;
    opacity: 0; }
  .header_info_time__block {
    padding-top: 15px; }
  .header_info__block {
    display: none; }
  .header_info_contacts__block {
    padding-bottom: 20px; }
  .header_info_address__block {
    display: block;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    margin-top: 15px;
    margin-bottom: 12px; }
  .header_content_search {
    transition: all .2s;
    position: absolute !important;
    top: 100px;
    left: 0;
    transform: translateX(-50%);
    width: 90%; }
    .header_content_search.show .header_content_item__block {
      top: 155px;
      visibility: hidden;
      opacity: 0; }
    .header_content_search.active {
      left: 50%; }
      .header_content_search.active .header_content_label {
        opacity: 1;
        width: 100%; }
      .header_content_search.active.show .header_content_item__block {
        top: 55px;
        visibility: visible;
        opacity: 1; }
  .header_content_shop {
    margin: 0;
    padding: 0px 15px;
    margin: 0px 15px;
    border-left: 1px solid #ACACAC;
    border-right: 1px solid #ACACAC; }
    .header_content_shop svg {
      width: 24px;
      height: 24px; }
    .header_content_shop_counter {
      right: 5px; }
  .header_mobile_search svg {
    width: 24px;
    height: 24px; } }

@media (max-width: 480px) {
  .last_registery__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px auto 0; }
  .last_registery_text {
    margin-right: 0; }
  .header_content_logo {
    margin-right: 10px;
    width: 100px; } }

@media (max-width: 420px) {
  .header.active .header_menu {
    left: calc(100vw - 60px); }
  .header_content_logo {
    width: 100px; }
    .header_content_logo img {
      width: 100%; }
  .header .header_content_nav__block {
    width: 100%; }
    .header .header_content_nav__block:after {
      display: none; }
  .header_mobile_search svg {
    width: 15px;
    height: 15px; }
  .header_content_shop svg {
    width: 15px;
    height: 15px; } }

@media (max-width: 380px) {
  .header_content__block {
    height: 90px; }
  .header_content__block .container {
    flex-direction: column;
    justify-content: center; }
  .header_content_left {
    width: 100%; }
  .header_content_logo {
    position: absolute !important;
    opacity: 0;
    top: 10px;
    width: 120px;
    left: 50%;
    height: 30px;
    transform: translateX(-50%); }
  .header_content_right {
    width: 100%;
    justify-content: space-around;
    margin-bottom: -23px; }
  .menu-trigger {
    top: -5px; }
  .btn_authenticator.modal_login {
    width: 24px;
    height: 24px; }
  .header.active .mobile_item {
    position: relative !important;
    opacity: 0;
    left: 0;
    transform: unset; } }
