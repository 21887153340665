.footer {
  position: relative;
  overflow: hidden;
  background: #2E2E2E;
  z-index: 99; }
  .footer_content_logo {
    display: block;
    margin-right: 20px; }
  .footer_content_nav {
    transition: all .2s;
    margin: 0px 16px; }
    .footer_content_nav:hover {
      color: #F28820; }
  .footer__block {
    padding: 27px 0px;
    background: #2E2E2E; }
    .footer__block .container {
      display: flex;
      align-items: center; }
  .footer_copyright {
    width: 100%;
    text-align: center;
    background: #242424;
    padding: 10px 0; }

.footer_starway__block {
  display: flex;
  align-items: center;
  position: absolute;
  right: 5%; }

.starway_logo {
  transition: all .2s;
  margin-left: 20px;
  display: flex;
  align-items: center; }
  .starway_logo:hover .svg_sw g {
    transition: all .2s;
    fill: #ffd10a; }

@media (max-width: 830px) {
  .footer_starway__block {
    bottom: 20px; }
  .footer__block .container {
    flex-direction: column;
    align-items: flex-start; }
  .footer_content_nav__block {
    display: flex;
    flex-direction: column; }
  .footer_content_nav {
    margin: 0;
    margin-top: 18px; } }

@media (max-width: 470px) {
  .footer_starway__block {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 20px;
    width: 100%;
    justify-content: flex-end; } }
