main {
  position: relative; }

.unselectable {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    not supported by any browser */ }

.padding_navbar {
  padding-top: 165px; }

.section_height {
  min-height: calc(100vh - 137px); }

.container {
  padding: 0px 5%;
  width: 100%; }

.upper_text {
  text-transform: uppercase; }

.absolute_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.sub_title {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 400; }
  .sub_title_0_1 {
    font-size: 10px; }
  .sub_title_0 {
    font-size: 12px; }
  .sub_title_1 {
    font-size: 14px; }
  .sub_title_2 {
    font-size: 16px; }

.midle_title {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal; }
  .midle_title_0 {
    font-size: 12px; }
  .midle_title_1 {
    font-size: 14px; }
  .midle_title_2 {
    font-size: 16px; }
  .midle_title_5 {
    font-size: 20px; }

.standart_title {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal; }
  .standart_title_0 {
    font-size: 12px; }
  .standart_title_1 {
    font-size: 14px; }
  .standart_title_3 {
    font-size: 18px; }
  .standart_title_5 {
    font-size: 24px; }
  .standart_title_7 {
    font-size: 30px; }
  .standart_title_8 {
    font-size: 32px; }

.bold_title {
  font-weight: 700;
  font-stretch: normal;
  font-style: normal; }
  .bold_title_0 {
    font-size: 12px; }
  .bold_title_2 {
    font-size: 16px; }
  .bold_title_10 {
    font-size: 56px; }

.bigger_title {
  font-weight: 900;
  font-stretch: normal;
  font-style: normal; }
  .bigger_title_0 {
    font-size: 12px; }
  .bigger_title_1 {
    font-size: 14px; }
  .bigger_title_2 {
    font-size: 16px; }
  .bigger_title_4 {
    font-size: 20px; }
  .bigger_title_6 {
    font-size: 28px; }

.color_red {
  color: #e52d23; }

.color_blue {
  color: #103989; }

.color_black {
  color: #000; }
  .color_black_1 {
    color: #333333; }

.color_white {
  color: #fff; }

.color_gray {
  color: #acacac; }
  .color_gray_0 {
    color: #7d7d7d; }
  .color_gray_1 {
    color: #f2f2f2; }
  .color_gray_2 {
    color: #19191d; }

.color_green {
  color: #09990b; }

.color_orange {
  color: #f28820; }

@media (max-width: 1345px) {
  .bold_title_10 {
    font-size: 36px; }
  .slider_info_btn {
    font-size: 16px; } }

@media (max-width: 992px) {
  .padding_navbar {
    padding-top: 140px; } }

@media (max-width: 725px) {
  .padding_navbar {
    padding-top: 95px; } }

@media (max-width: 625px) {
  .padding_navbar {
    padding-top: 90px; } }

@media (max-width: 420px) {
  .bigger_title_6 {
    font-size: 22px; }
  .sub_title_2 {
    font-size: 14px; }
  .standart_title_7 {
    font-size: 22px; }
  .bigger_title_4 {
    font-size: 17px; } }

@media (max-width: 380px) {
  .padding_navbar {
    padding-top: 110px; } }

@media (max-width: 390px) {
  .standart_title_8 {
    font-size: 26px; } }
