.contact_section {
  position: relative;
  padding-bottom: 90px; }

.contact__block {
  display: flex; }

.contact_form {
  width: 370px;
  margin-right: 30px; }

.contact_info {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 40px;
  padding-bottom: 0; }
  .contact_info__block {
    background: #F9F9F9;
    width: calc(100% - 400px); }
  .contact_info_profile {
    display: flex;
    margin-bottom: 5px;
    width: 50%;
    margin-bottom: 25px; }
    .contact_info_profile_svg {
      margin-right: 15px;
      width: 25px;
      height: 25px; }
      .contact_info_profile_svg.location {
        padding: 3px; }
      .contact_info_profile_svg img {
        width: 100%;
        height: 100%; }
    .contact_info_profile__wrap {
      width: calc(100% - 40px); }
    .contact_info_profile_link {
      display: block;
      margin-top: 3px; }
    .contact_info_profile a {
      position: relative;
      transition: all .2s; }
      .contact_info_profile a:hover {
        color: #F28820; }
  .contact_info_map {
    height: 100%;
    width: 100%; }

.section_title {
  margin-bottom: 25px; }

@media (max-width: 992px) {
  .contact__block {
    flex-direction: column-reverse; }
  .contact_info__block {
    background: #f9f9f9;
    width: 100%; }
  .contact_info_map {
    height: 300% !important; }
  .contact_form {
    width: 100%;
    margin-right: 0;
    margin-top: 40px; }
  .contact_section {
    position: relative;
    padding-bottom: 40px; } }

@media (max-width: 630px) {
  .contact_info {
    flex-direction: column;
    padding: 20px; }
  .contact_info_profile {
    display: flex;
    width: 100%; } }
