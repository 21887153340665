.btn_standart {
  transition: all .2s;
  min-width: 170px;
  display: block;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: normal;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  padding: 14.5px 10px; }
  .btn_standart_orange {
    background: #F28820;
    border: solid 1px transparent !important; }
    .btn_standart_orange:hover {
      border: solid 1px #cd731a !important;
      background: #cd731a; }
  .btn_standart_white {
    background: #fff;
    border: solid 1px #F28820 !important; }
    .btn_standart_white:hover {
      border: solid 1px #F28820 !important;
      background: #F28820;
      color: #fff; }
