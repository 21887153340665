.select2-container--default .select2-selection--single {
  border: none;
  outline: none; }

.select2-dropdown {
  border: none;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
  border-radius: 2px; }

.select2-results__option {
  padding: 10px;
  transition: all .2s; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba(242, 137, 32, 0.1); }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(242, 137, 32, 0.1);
  color: #101010; }

.input_select {
  height: 50px; }
  .input_select__wrap {
    margin-bottom: 15px; }
    .input_select__wrap .select2-container--default .select2-selection--single {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
      border: 1px solid #8C8C8C;
      border-radius: 4px; }
    .input_select__wrap .select2-container {
      height: 100%; }
    .input_select__wrap .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 50%;
      right: 10px;
      transform: translateY(-50%); }
  .input_select_label {
    margin-bottom: 10px; }
