.slider_section {
  padding-bottom: 70px; }
  .slider_section .container {
    position: relative; }

.slider_prof {
  position: relative;
  height: 500px; }

.slider_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .slider_img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.slider_btn {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.slider_text {
  margin-top: 2px;
  margin-bottom: 7px; }

.slider_arrow {
  transition: all .2s;
  position: relative;
  right: 0; }
  .slider_arrow_next {
    transition: all .2s;
    cursor: pointer;
    position: absolute;
    z-index: 9;
    background: #F28820;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 13%; }
    .slider_arrow_next:hover {
      transform: translateY(-50%) scale(1.1); }

.slider_content {
  display: flex;
  flex-direction: column;
  transition: all .6s;
  position: absolute;
  width: 300px;
  min-height: 100px;
  background: rgba(255, 255, 255, 0.72);
  backdrop-filter: blur(31px);
  padding: 10px;
  right: 25px;
  bottom: -100px;
  opacity: 0; }
  .slider_content:hover {
    background: rgba(255, 255, 255, 0.9); }
    .slider_content:hover .slider_arrow {
      right: -5px; }

.slider_info__block {
  transition: all .2s;
  position: absolute;
  z-index: 9;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.72);
  backdrop-filter: blur(31px);
  width: 430px;
  padding: 15px 25px;
  left: 15%; }

.slider_info_title {
  line-height: 1.2;
  margin-bottom: 12px; }

.slider_info_btn {
  border-radius: 72px;
  padding: 8px 15px;
  width: 300px;
  margin: 0 auto;
  margin-top: 15px; }

.slider_section .slick-current .slider_content {
  bottom: 25px;
  opacity: 1; }

.slider_section .slick-slide {
  margin: 0 15px; }

.slider_section .slick-dots {
  position: absolute;
  bottom: calc(0% - 55px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  padding: 0; }
  .slider_section .slick-dots li {
    position: relative;
    cursor: pointer;
    transition: all .4s;
    list-style: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ACACAC;
    margin: 0px 15px; }
    .slider_section .slick-dots li:after {
      transition: all .4s;
      width: 2px;
      height: 2px;
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      border-radius: 50%;
      border: 1px solid transparent;
      transform: translate(-50%, -50%); }
    .slider_section .slick-dots li button {
      display: none; }
  .slider_section .slick-dots .slick-active {
    background: #F28820; }
    .slider_section .slick-dots .slick-active:after {
      border: 1px solid #F28820;
      width: 28px;
      height: 28px; }

.partners_section {
  position: relative;
  padding-top: 50px;
  padding-bottom: 80px; }

.partners__block {
  display: flex;
  flex-wrap: wrap;
  margin-left: -11px;
  margin-right: -11px;
  justify-content: space-between; }

.partners_profile {
  margin: 11px; }

@media (max-width: 1345px) {
  .slider_info__block {
    left: 7%; } }

@media (max-width: 1190px) {
  .slider_content {
    bottom: 20px;
    right: 20px;
    padding: 14px; } }

@media (max-width: 1120px) {
  .slider_info__block {
    position: relative;
    top: 0;
    left: 0;
    transform: unset;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
    align-items: flex-start; }
  .slider_info_btn {
    border-radius: 4px;
    width: 190px; }
  .slider_arrow_next {
    display: none; }
  .slider_content {
    width: 100%;
    right: 0;
    bottom: -100px !important; }
  .slider_section .slick-slide {
    margin: 0;
    margin-right: 15px; }
  .slider_section .slick-current .slider_content {
    bottom: 0 !important; }
  .slider__block .slick-list {
    padding-left: 0 !important; } }

@media (max-width: 900px) {
  .slider_prof {
    height: 360px; }
  .slider_content {
    backdrop-filter: unset; } }

@media (max-width: 525px) {
  .slider_prof {
    height: 250px; }
  .slider_section .slick-current .slider_content {
    height: 100%;
    justify-content: center;
    background: rgba(255, 255, 255, 0.82); }
  .partners_section {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px; }
  .section_title {
    margin-bottom: 5px; } }

@media (max-width: 380px) {
  .slider_prof {
    height: 170px; }
  .partners_profile {
    width: calc(50% - 22px); }
    .partners_profile img {
      width: 100%; } }
