.catalog_items_section {
  position: relative;
  padding-bottom: 40px; }

.catalog_items__block {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -12.5px; }

.catalog_items_profile {
  transition: all .2s;
  outline: none;
  display: block;
  margin: 12.5px;
  padding: 12px;
  position: relative;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px;
  width: calc(25% - 25px);
  animation: .6s ease-in-out both fade-in; }
  .catalog_items_profile .form_stars {
    transform: scale(0.5);
    pointer-events: none; }
  .catalog_items_profile:hover {
    box-shadow: 0px 2px 4px rgba(59, 69, 123, 0.2), 0px 4px 8px rgba(92, 107, 192, 0.2); }
  .catalog_items_profile_type {
    display: block; }
  .catalog_items_profile_text {
    transition: all .2s;
    display: block; }
  .catalog_items_profile_availability {
    position: absolute;
    top: 10px;
    right: 10px; }
  .catalog_items_profile .btn_standart_white {
    padding: 5px 10px;
    width: 100%;
    margin-top: 5px; }
  .catalog_items_profile_rate {
    display: flex;
    margin-left: -40px; }
    .catalog_items_profile_rate__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      margin-bottom: 5px; }
  .catalog_items_profile_price__block {
    margin: 12px 0;
    min-height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .catalog_items_profile_img {
    position: relative;
    display: block;
    width: 100%;
    height: 250px;
    overflow: hidden; }
    .catalog_items_profile_img img {
      transition: all .4s;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .catalog_items_profile_btn {
    cursor: pointer;
    padding: 5px 10px;
    width: 100%; }
  .catalog_items_profile_link {
    cursor: pointer; }
    .catalog_items_profile_link:hover .catalog_items_profile_text {
      color: #f28820; }

.product_card-prof:nth-child(1) {
  animation-delay: 0.06s; }

.product_card-prof:nth-child(2) {
  animation-delay: 0.12s; }

.product_card-prof:nth-child(3) {
  animation-delay: 0.18s; }

.product_card-prof:nth-child(4) {
  animation-delay: 0.24s; }

.product_card-prof:nth-child(5) {
  animation-delay: 0.3s; }

.product_card-prof:nth-child(6) {
  animation-delay: 0.36s; }

.product_card-prof:nth-child(7) {
  animation-delay: 0.42s; }

.product_card-prof:nth-child(8) {
  animation-delay: 0.48s; }

.product_card-prof:nth-child(9) {
  animation-delay: 0.54s; }

.product_card-prof:nth-child(10) {
  animation-delay: 0.6s; }

.product_card-prof:nth-child(11) {
  animation-delay: 0.66s; }

.product_card-prof:nth-child(12) {
  animation-delay: 0.72s; }

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.best_catalog_section {
  position: relative;
  margin-top: 70px;
  margin-bottom: 80px; }

.best_catalog_title {
  margin-bottom: 35px; }

@media (max-width: 1222px) {
  .catalog_items_profile {
    width: calc(33.3% - 25px); } }

@media (max-width: 992px) {
  .catalog_items_profile .catalog_items_profile_img {
    height: 200px; } }

@media (max-width: 840px) {
  .catalog_items_profile .catalog_items_profile_img {
    height: 160px; } }

@media (max-width: 660px) {
  .catalog_items_profile {
    width: calc(50% - 25px); } }

@media (max-width: 480px) {
  .catalog_items_section {
    padding-bottom: 10px; }
  .catalog_items_profile {
    padding: 10px;
    margin: 10px; }
    .catalog_items_profile .catalog_items_profile_img {
      height: 130px; }
  .catalog_items_profile_rate__block {
    flex-direction: column;
    align-items: flex-start; }
  .catalog_items_profile_btn {
    font-size: 12px;
    min-width: unset; } }

@media (max-width: 380px) {
  .shop_sorting_select {
    width: 100%; }
  .catalog_items_profile {
    width: 100%;
    margin: 0;
    margin-bottom: 20px; }
  .catalog_items_profile .catalog_items_profile_img {
    height: auto; } }
